<template>
  <div>
    <LoginForm />
  </div>
</template>
<script>
import LoginForm from "@/components/Auth/LoginForm";

export default {
  components: { LoginForm },
};
</script>
<style></style>
