<template>
  <div>
    <div class="splash-container min-vh-100">
      <div class="card card-border-color card-border-color-secondary">
        <div class="card-body text-center">
          <img
              class="logo-img"
              src="/img/brand/logo.png"
              alt="Mshiriki"
              width="180"
              height="70"
          />
          <div class="my-4">
            <h4 class="text-center my-0">ARED ADMINISTRATOR</h4>
            <h3 class="text-center mt-1">LOGIN</h3>
          </div>
          <hr/>
          <div class="my-3">
            <div v-show="error" class="alert alert-danger">{{ error }}</div>
            <form @submit.prevent="login()">
              <div class="form-group text-left">
                <label for="email">E-mail:</label>
                <input
                    v-model.trim="user.email"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="E-mail address"
                    autofocus
                    class="form-control"
                    required
                />
                <small class="text-danger">{{ errors.first("email") }}</small>
              </div>
              <div class="form-group text-left">
                <label for="password">Password:</label>
                <div class="input-group">
                  <input
                      v-model.trim="user.password"
                      v-validate="'required|min:6'"
                      :type="passFieldType"
                      name="password"
                      id="password"
                      placeholder="Password"
                      minlength="8"
                      class="form-control"
                      required
                  />
                  <div class="input-group-append showPassDiv">
                    <i
                        :class="[
                      'mdi form-control show_password_icon',
                      showPass ? 'mdi-eye-off' : 'mdi-eye',
                    ]"
                        @click="showPassword"
                    ></i>
                  </div>
                </div>
                <small class="text-danger">{{ errors.first("password") }}</small>
              </div>
              <div class="form-group login-tools text-left">
                <div class="mb-2">
                  <router-link :to="{ name: 'forgot-password' }">
                    Forgot Password?
                  </router-link
                  >
                </div>
              </div>
              <div class="form-group login-submit">
                <button
                    :disabled="loginBtn.disabled"
                    class="btn btn-primary btn-block"
                    type="submit"
                >
                  {{ loginBtn.text }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="splash-footer text-white">
        <span>© {{ year }} ARED</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "loginForm",
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      year: new Date().getFullYear(),
      error: "",
      showPass: false,
      passFieldType: "password",
      showPassIconClass: "i-Eye-Scan form-control show_password_icon",
    };
  },
  computed: {
    ...mapGetters(["loginBtn"])
  },
  methods: {
    ...mapActions(["logIn"]),
    async login() {
      this.error = "";

      await this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
        this.logIn({
          phone: this.user.email,
          password: this.user.password,
        }).then(loginSuccess => {
          console.log(loginSuccess);
          if (loginSuccess === false) this.submitting = false;
        });
      });
    },
    showPassword() {
      this.showPass = !this.showPass;
    },
  },
  watch: {
    showPass() {
      if (this.showPass === true) {
        this.passFieldType = "text";
      } else {
        this.passFieldType = "password";
      }
    },
  },
};
</script>

